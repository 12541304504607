import { SET_ANSWER } from "../actionTypes";

const initialState = {};

const answersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANSWER:
      return {...state, [action.questionId]: action.answer};

    default:
      return state;
  }
};

export default answersReducer;
