import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link, withRouter } from "react-router-dom";

import Header from "../../components/Header";
import client from "../../modules/client";
import { REMOVE_USER } from "../../store/actionTypes";
import Loading from "../../components/Loading";

import icChat from "../../images/ic-chat.png";

const NO_TEST = "notest";
const HAVE_TO_SHARE = "havetoshare";
const ALL_DONE = "alldone";

const useStyles = makeStyles({
  wrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
    padding: "16px 16px 66px",
    overflow: "auto",
  },
  myInfo: {
    width: "100%",
    position: "relative",
    marginTop: 90,
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  },
  takeTestWrapper: {
    marginTop: 90,
    textAlign: "center",
  },
  takeTestBtn: {
    marginBottom: 24,
  },
  descNoticeWrapper: {
    marginBottom: 24,
  },
  descNotice: {
    color: "#555",
  },
  logoutWrapper: {
    marginTop: 48,
    textAlign: "center",
  },
  logoutBtn: {
    fontSize: 18,
  },
  shareBtn: {
    marginBottom: 36,
    fontWeight: "bold",
    color: "#fff",
  },
  uselessDiv: {
    height: 36,
    width: "100%",
  },
  formLink: {
    width: "100%",
    height: "100%",
  },
  linkToBuyBook: {
    width: "100%",
    height: "100%",
  },
});

const MyPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const [loading, setLoading] = useState(true);
  const [mypageDescCase, setMypageDescCase] = useState(NO_TEST);

  const logout = () => {
    dispatch({ type: REMOVE_USER });
  };

  useEffect(() => {
    const userId = user.id;
    client({
      method: "get",
      url: "/getLastUserAnswer",
      params: {
        userId: userId,
      },
    }).then((res) => {
      // 검사 한 사람
      setLoading(false);
      if (res.data !== null) {
        const testId = res.data;
        client({
          method: "get",
          url: "/getMyMatchAnswer",
          params: {
            userId: userId,
          },
        }).then((res) => {
          if (res.data === true) {
            // 검사도 했고 매칭도 한 사람
            setMypageDescCase(ALL_DONE);
            window.Kakao.Link.createDefaultButton({
              container: "#kakao-link-btn",
              objectType: "feed",
              content: {
                title: "자녀와 부모 강점 성향 비교 검사",
                description:
                  "메타인지 능력 향상을 위한 강점 성향 비교 검사 결과를 받아보세요.",
                imageUrl: "https://personalitytest.co.kr/og_image.png",
                link: {
                  mobileWebUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`,
                  webUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`,
                },
              },
              buttons: [
                {
                  title: "무료 테스트 바로가기",
                  link: {
                    mobileWebUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`,
                    webUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`,
                  },
                },
              ],
            });
          } else {
            // 검사는 했는데 아직 매칭은 안된 사람
            setMypageDescCase(HAVE_TO_SHARE);
            window.Kakao.Link.createDefaultButton({
              container: "#kakao-link-btn",
              objectType: "feed",
              content: {
                title: "자녀와 부모 강점 성향 비교 검사",
                description:
                  "메타인지 능력 향상을 위한 강점 성향 비교 검사 결과를 받아보세요.",
                imageUrl: "https://personalitytest.co.kr/og_image.png",
                link: {
                  mobileWebUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`,
                  webUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`,
                },
              },
              buttons: [
                {
                  title: "무료 테스트 바로가기",
                  link: {
                    mobileWebUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`,
                    webUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`,
                  },
                },
              ],
            });
          }
        });
      } else {
        // 검사 한 번도 안 한 사람
        setMypageDescCase(NO_TEST);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const descDivs =
    mypageDescCase === NO_TEST ? (
      <>
        <Button
          component={Link}
          to="/take-test"
          className={classes.takeTestBtn}
          variant="contained"
          size="large"
        >
          검사 시작하기
        </Button>
        <div className={classes.descNoticeWrapper}>
          <Typography className={classes.descNotice} variant="body2">
            ※ 약 100문항으로 7분 정도 소요됩니다.
          </Typography>
          <Typography className={classes.descNotice} variant="body2">
            ※ 부모님 먼저 검사 후 아이에게 공유해주세요.
          </Typography>
          <Typography className={classes.descNotice} variant="body2">
            ※ 결과는 24시간 내에 이메일로 전송됩니다.
          </Typography>
          <Typography className={classes.descNotice} variant="body2">
            ※ 문제가 발생한 경우 아래 이메일로 연락주세요.
          </Typography>
          <Typography className={classes.descNotice} variant="body2">
            info@personalitytest.co.kr
          </Typography>
        </div>
      </>
    ) : mypageDescCase === HAVE_TO_SHARE ? (
      <Button
        id="kakao-link-btn"
        variant="contained"
        color="primary"
        size="large"
        fullWidth={true}
        className={classes.shareBtn}
      >
        카카오톡으로 설문 요청하기
      </Button>
    ) : (
      <>
        <div className={classes.uselessDiv}>
          [자녀와 부모 강점 성향 비교 검사] 에
        </div>
        <div className={classes.uselessDiv}>참여해주셔서 감사드립니다.</div>
        <div className={classes.uselessDiv}>
          결과는 카카오톡에 가입된 메일로 전송됩니다.
        </div>
        <div className={classes.uselessDiv}>[{user.email}]</div>
        <div className={classes.uselessDiv}></div>
        <div className={classes.uselessDiv}></div>
        {/* <div className={classes.uselessDiv}>
          레포트 확인 후 설문조사에 참여해주세요. 🙇‍♂️
        </div> */}
        <div className={classes.uselessDiv}>
          나와 비교하고자 하는 상대에게
        </div>
        <div className={classes.uselessDiv}>
          아래 버튼을 눌러 카카오톡을 보내주세요.
        </div>
        {/* <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth={true}
          className={classes.shareBtn}
        >
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSe6OrCW311djLTMfq7xFMznq7jbyxWZmFdS5wPsjJbp-WYkig/viewform?usp=sf_link"
            target="_blank"
            rel="noreferrer"
            className={classes.formLink}
          >
            설문조사 하러가기
          </a>
        </Button> */}
        <Button
          id="kakao-link-btn"
          variant="contained"
          size="large"
          fullWidth={true}
          className={classes.shareBtn}
          style={{
            backgroundColor: "#f9e000",
            color: "#000"
          }}
        >
          <span><img src={icChat} alt="ic-chat" style={{width: "16px", height: "16px", marginRight: "4px", marginTop: "6px"}} /></span>카카오톡으로 설문 보내기
        </Button>
        <div className={classes.uselessDiv}></div>
        <div className={classes.uselessDiv}>혹시 문제가 있으신가요?</div>
        <div className={classes.uselessDiv}>
          아래 버튼을 눌러 문제 상황에 대해 알려주세요.
        </div>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth={true}
          className={classes.shareBtn}
        >
          <a
            href={`https://docs.google.com/forms/d/e/1FAIpQLSeMvUAcrHH-iYhj7Jr-dgcN80eMvnT7GzKuls4CqZIhjFF1uQ/viewform?usp=pp_url&entry.764284634=${user.id}`}
            target="_blank"
            rel="noreferrer noopenner"
            className={classes.formLink}
          >
            문제 상황 신고하기
          </a>
        </Button>
      </>
    );

  return loading ? (
    <Loading />
  ) : (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.myInfo}>안녕하세요, {user.displayName}님</div>
      <div className={classes.takeTestWrapper}>{descDivs}</div>
      <div className={classes.logoutWrapper}>
        <Button onClick={logout} className={classes.logoutBtn}>
          로그아웃
        </Button>
      </div>
    </div>
  );
};

export default withRouter(MyPage);
