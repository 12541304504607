import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles';
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles(() => ({
  questionWrapper: {
    marginTop: '150px'
  },
  formControl: {
    width: '100%',
    height: '50px',
    marginBottom: '16px',
  },
  questionDescription: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    height: '60px',
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '1.5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '24px',
    color: '#444'
  },
  radioRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  labelRoot: {
    marginLeft: 0,
    marginRight: 0
  },
  radioRoot: {
    padding: 0
  },
  smallIcon: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  smallCheckedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 26,
    height: 26,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 26,
      height: 26,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  largeIcon: {
    borderRadius: '50%',
    width: 36,
    height: 36,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  largeCheckedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 36,
      height: 36,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  extraLargeIcon: {
    borderRadius: '50%',
    width: 45,
    height: 45,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  extraLargeCheckedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 45,
      height: 45,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  guideWrapper: {
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 12,
  }
}))

function SmallRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.radioRoot}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.smallIcon, classes.smallCheckedIcon)} />}
      icon={<span className={classes.smallIcon} />}
      {...props}
    />
  );
}
function MediumRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.radioRoot}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
function LargeRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.radioRoot}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.largeIcon, classes.largeCheckedIcon)} />}
      icon={<span className={classes.largeIcon} />}
      {...props}
    />
  );
}
function ExtraLargeRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.radioRoot}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.extraLargeIcon, classes.extraLargeCheckedIcon)} />}
      icon={<span className={classes.extraLargeIcon} />}
      {...props}
    />
  );
}

const QuestionItem = ({ question, setAnswer }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    const values = event.target.value.split("-")
    const questionId = parseInt(values[1])
    const questionAnswer = parseInt(values[2])
    const data = {
      id: questionId,
      description: question.description,
      value: questionAnswer,
      idx: parseInt(values[4])
    }
    setAnswer(data)
  };

  return question ? (
    <div className={classes.questionWrapper}>
      <div className={classes.questionDescription}>{question.description}</div>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          row
          name={"question-" + question.id}
          value={value}
          onChange={handleChange}
          className={classes.radioRow}
        >
          <FormControlLabel
            className={classes.labelRoot}
            value={"question-" + question.id + "-1-idx-" + question.idx}
            control={<ExtraLargeRadio />}
          />
          <FormControlLabel
            className={classes.labelRoot}
            value={"question-" + question.id + "-2-idx-" + question.idx}
            control={<LargeRadio />}
          />
          <FormControlLabel
            className={classes.labelRoot}
            value={"question-" + question.id + "-3-idx-" + question.idx}
            control={<MediumRadio />}
          />
          <FormControlLabel
            className={classes.labelRoot}
            value={"question-" + question.id + "-4-idx-" + question.idx}
            control={<SmallRadio />}
          />
          <FormControlLabel
            className={classes.labelRoot}
            value={"question-" + question.id + "-5-idx-" + question.idx}
            control={<MediumRadio />}
          />
          <FormControlLabel
            className={classes.labelRoot}
            value={"question-" + question.id + "-6-idx-" + question.idx}
            control={<LargeRadio />}
          />
          <FormControlLabel
            className={classes.labelRoot}
            value={"question-" + question.id + "-7-idx-" + question.idx}
            control={<ExtraLargeRadio />}
          />
        </RadioGroup>
      </FormControl>
      <div className={classes.guideWrapper}>
        <div>매우 아니다</div>
        <div>매우 그렇다</div>
      </div>
    </div>
  ) : null
}

export default QuestionItem