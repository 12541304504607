import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux'

import client from '../../modules/client'
import Header from '../../components/Header'
import { SET_USER } from '../../store/actionTypes';
import Loading from '../../components/Loading';

const useStyles = makeStyles({
  boldUnderline: {
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: '0 16px 66px',
    overflow: 'auto',
    textAlign: 'center',
  },
  h5: {
    marginTop: '156px',
    fontSize: '30px',
    fontWeight: 'bold',
    marginBottom: 72
  },
  subBodyWrapper: {
    marginBottom: 36
  },
  subBody: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  loginBtn: {
    marginTop: 36
  }
})

const Login = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles()
  const referrerId = useSelector(state => state.referrer.id)
  const referrerTestId = useSelector(state => state.referrer.testId)

  React.useEffect(() => {
    window.Kakao.Auth.createLoginButton({
      container: '#kakao-login-btn',
      success: handleSuccess,
      fail: handleFail,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSuccess = res => {
    setLoading(true)
    client({
      method: 'post',
      url: '/auth/verifyKakaoToken',
      data: { token: res.access_token }
    }).then(res => {
      const user = res.data;
      dispatch({ type: SET_USER, data: user })
      setLoading(false)
      if (referrerId !== null) {
        client({
          method: 'post',
          url: '/referrer',
          data: {
            referrerId: referrerId,
            referrerTestId: referrerTestId,
            userId: res.data.id,
          }
        })
      }
    })
  }

  const handleFail = err => {
    console.log(err)
  }

  return loading ? <Loading /> : (
    <div className={classes.wrapper}>
      <Header />
      <Typography variant="h5" className={classes.h5} >로그인</Typography>
      <div className={classes.subBodyWrapper}>
        <Typography variant="body1" className={classes.subBody} >
          아래 로그인 버튼을 눌러
        </Typography>
        <Typography variant="body1" className={classes.subBody} >
          설문조사를 시작해주세요.
        </Typography>
      </div>
      <button className={classes.loginBtn} id="kakao-login-btn">카카오톡 로그인</button>
    </div>
  )
}

export default Login