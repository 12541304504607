import React from 'react'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';

import { Link, withRouter } from 'react-router-dom'

const useStyles = makeStyles({
  bottomNavigation: {
    width: '100%',
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#eee',
    maxWidth: '444px',
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 9
  }
})

const BottomNavBar = () => {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname === "/") {
      setValue(0)
    } else {
      setValue(1)
    }
  }, [])

  const classes = useStyles()
  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.bottomNavigation}
    >
      <BottomNavigationAction
        label="홈"
        icon={<HomeIcon />}
        component={Link}
        to="/"
      />
      <BottomNavigationAction
        label="마이페이지"
        icon={<PersonIcon />}
        component={Link}
        to="/mypage"
      />
    </BottomNavigation>
  )
}

export default withRouter(BottomNavBar)