import { SET_REFERRER } from '../actionTypes'

const initialState = {
  id: null,
  testId: null,
}

const referrerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REFERRER:
      return {
        ...state,
        id: action.id,
        testId: action.testId,
      }

    default:
      return state
  }
}

export default referrerReducer;