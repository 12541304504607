import { makeStyles } from "@material-ui/styles";
import loadingIcon from '../images/Wedges-3s-200px.svg'

const useStyles = makeStyles({
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
})

function Loading() {
  const classes = useStyles()

  return (
    <div className={classes.loadingWrapper}><img src={loadingIcon} alt="loading..." /></div>
  )
}

export default Loading