import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useLocation } from 'react-router-dom'

import testIntroImg from '../../images/img-test-intro.jpg'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: "48px 12px 0"
  },
  congrats: {
    textAlign: 'center',
    fontSize: '18px',
    lineHeight: 1.2
  },
  bigDescStart: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: 48
  },
  shareInfo: {
    fontSize: 18,
    lineHeight: 1.2,
    textAlign: 'center'
  },
  noPhoneInfo: {
    fontSize: 18,
    lineHeight: 1.2,
    textAlign: 'center',
    paddingBottom: 64
  },
  imgWrapper: {
    marginTop: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  descIntroImg: {
    width: 252,
    height: 228,
    marginBottom: 24
  },
  shareBtn: {
    marginTop: 36,
    fontWeight: 'bold',
    marginBottom: 24
  },
  marginDiv: {
    paddingTop: 120
  }
})

const ShareTest = () => {
  const userId = useQuery().get("userId")
  const testId = useQuery().get("testId")
  const referrerId = useSelector(state => state.referrer.id)
  const classes = useStyles()

  useEffect(() => {
    if (referrerId === null) {
      window.Kakao.Link.createDefaultButton({
        container: '#kakao-link-btn',
        objectType: 'feed',
        content: {
          title: '자녀와 부모 강점 성향 비교 검사',
          description: '메타인지 능력 향상을 위한 강점 성향 비교 검사 결과를 받아보세요.',
          imageUrl: 'https://personalitytest.co.kr/og_image.png',
          link: {
            mobileWebUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`,
            webUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`
          }
        },
        buttons: [
          {
            title: '무료 테스트 바로가기',
            link: {
              mobileWebUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`,
              webUrl: `https://vip.personalitytest.co.kr?referrerId=${userId}&referrerTestId=${testId}`
            }
          },
        ]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shareDivs = referrerId === null ? (
    <div className={classes.shareDivWrapper}>
      <div className={classes.bigDescStart}>
        이제, 비교 분석을 요청하세요!
      </div>
      <div className={classes.shareInfo}>
        아래 버튼을 눌러 카카오톡으로
      </div>
      <div className={classes.shareInfo}>
        분석을 원하는 상대에게 공유해주세요.
      </div>
      <Button
        id="kakao-link-btn"
        variant="contained"
        color="primary"
        size="large"
        fullWidth={true}
        className={classes.shareBtn}
      >
        카카오톡으로 설문 공유하기
      </Button>
      <div className={classes.shareInfo}>
        ※ 아이가 카톡이 없는 경우,
      </div>
      <div className={classes.shareInfo}>
        내 카톡으로 공유한 후
      </div>
      <div className={classes.noPhoneInfo}>
        내 폰에서 아이의 설문을 진행해주세요.
      </div>
    </div>
  ) : null

  const marginDiv = referrerId !== null ? (
    <div className={classes.marginDiv}></div>
  ) : null

  return (
    <div className={classes.wrapper}>
      {marginDiv}
      <div className={classes.congrats}>
        축하합니다!
      </div>
      <div className={classes.congrats}>
        꼼꼼하게 분석하여 결과 레포트를
      </div>
      <div className={classes.congrats}>
        24시간 내 이메일로 보내드립니다.
      </div>
      <br />
      <div className={classes.congrats}>
        ※ 스팸메일함도 확인해주세요.
      </div>
      <div className={classes.congrats}>
        ※ 문의 : info@personalitytest.co.kr
      </div>
      <div className={classes.imgWrapper}>
        <img className={classes.descIntroImg} src={testIntroImg} alt="introimg" />
      </div>
      {shareDivs}
    </div>
  )
}

export default ShareTest
