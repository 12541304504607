import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import imgLogo from '../images/img-header-logo.png'

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: 60,
    fontSize: 20,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ccc',
    borderBottom: '1px solid #ccc'
  },
  logoImg: {
    width: 150,
    height: 27
  }
})

const Header = () => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <img
        src={imgLogo}
        alt="logo"
        className={classes.logoImg}
      />
    </div>
  )
}

export default Header