import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link, withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";

import testIntroImg from "../../images/img-test-intro.jpg";
import testTitleImg from "../../images/img-title.png";
import { SET_REFERRER } from "../../store/actionTypes";
import Header from "../../components/Header";
import imgEvent from "../../images/img-event.png";
import imgBook from "../../images/img-book.png";
import imgHowTo from "../../images/introductions/how-to.png";
import imgFirst from "../../images/introductions/1_1.png";
import imgShare from "../../images/introductions/2.png";
import imgKid from "../../images/introductions/3.png";
import imgMail from "../../images/introductions/4.png";
import SamplePdf from "../../files/sample.pdf";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles({
  wrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  descWrapper: {
    marginTop: "30px",
    width: "100%",
    position: "relative",
    padding: "16px 16px 0",
  },
  descIntroduction: {
    position: "relative",
    textAlign: "center",
    paddingBottom: 20,
  },
  descIntroImg: {
    width: 252,
    height: 228,
    marginBottom: 24,
  },
  descTitleImg: {
    width: 244,
    height: 70,
  },
  descBody: {
    marginTop: 20,
    padding: "0 20px",
    fontSize: 18,
    marginBottom: 20,
  },
  takeTestBtn: {
    width: 250,
  },
  header: {
    width: "100%",
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  eventImg: {
    width: 230,
    height: 27,
  },
  bookImgWrapper: {
    marginTop: 36,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bookImg: {
    width: 235,
    height: 340,
  },
  bookDescWrapper: {
    marginTop: 32,
    padding: "0 20px",
    fontSize: 16,
    marginBottom: 20,
    lineHeight: 1.5,
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  buyBookWrapper: {
    position: "relative",
  },
  buyBook: {
    marginTop: 24,
    width: 250,
    position: "relative",
    backgroundColor: "#f36457",
  },
  linkToBuyBook: {
    width: "100%",
    height: "100%",
  },
  sampleBtn: {
    marginTop: 24,
    width: 250,
    position: "relative",
    backgroundColor: "#e7a378",
  },
  hrLine: {
    marginTop: 96,
    marginBottom: 24,
    width: 200,
    height: 1,
    backgroundColor: "#ccc",
    marginLeft: "auto",
    marginRight: "auto",
  },
  lineThrough: {
    textDecoration: "line-through #f36457 1px",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  imgTitleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imgTitle: {
    width: 203,
    height: 27,
  },
  titleWrapper: {
    marginTop: 24,
  },
  instructionImgWrapper: {
    marginTop: 96,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imgFirst: {
    width: 300,
    height: 151,
  },
  infoWrapper: {
    marginTop: 16,
    marginBottom: 48,
  },
  info: {
    marginTop: 4,
    fontSize: 16,
    lineHeight: 1.2,
    color: "#333",
  },
  nextBtnWrapper: {
    marginTop: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sampleBtnWrapper: {
    paddingBottom: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nextBtn: {
    width: 250,
    position: "relative",
  },
  report: {
    marginTop: 12,
    textAlign: "right",
    textDecoration: "underline",
    color: "#333",
    float: "right",
  },
  clear: {
    clear: "both",
  },
  instructionWrapper: {
    padding: "0 20px",
  },
});

const Home = () => {
  const classes = useStyles();
  const referrerId = useQuery().get("referrerId");
  const referrerTestId = useQuery().get("referrerTestId");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    if (referrerId !== null) {
      dispatch({ type: SET_REFERRER, id: referrerId, testId: referrerTestId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportLink = user
    ? `https://docs.google.com/forms/d/e/1FAIpQLSeMvUAcrHH-iYhj7Jr-dgcN80eMvnT7GzKuls4CqZIhjFF1uQ/viewform?usp=pp_url&entry.764284634=${user.id}`
    : `https://docs.google.com/forms/d/e/1FAIpQLSeMvUAcrHH-iYhj7Jr-dgcN80eMvnT7GzKuls4CqZIhjFF1uQ/viewform?usp=pp_url&entry.764284634=guest`;

  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.descWrapper}>
        <div className={classes.descIntroduction}>
          <img
            className={classes.descIntroImg}
            src={testIntroImg}
            alt="test-intro-img"
          />
          <img
            className={classes.descTitleImg}
            src={testTitleImg}
            alt="test-title-img"
          />
          <Typography className={classes.descBody} variant="body1">
            간단하면서도 정확한 강점 비교 검사를 통해 나의 성향은 어떠하며
            아이와 왜 그러한 행동이 나타나는지 확인해보세요.
          </Typography>
          <Button
            component={Link}
            to="/take-test"
            className={classes.takeTestBtn}
            variant="contained"
            size="large"
            color="primary"
            style={{ fontWeight: "bold", color: "#fff" }}
          >
            무료 검사 시작하기
          </Button>
          <Button
            className={classes.sampleBtn}
            variant="contained"
            size="large"
            color="inherit"
            style={{ fontWeight: "bold", color: "#fff" }}
          >
            <a href={SamplePdf} target="_blank" rel="noopener noreferrer">
              검사 결과 미리보기
            </a>
          </Button>
        </div>
      </div>
      <div className={classes.hrLine}></div>
      <div className={classes.header}>
        <img src={imgEvent} alt="event-header" className={classes.eventImg} />
      </div>
      <div className={classes.bookImgWrapper}>
        <img src={imgBook} alt="book-cover" className={classes.bookImg} />
      </div>
      <div className={classes.bookDescWrapper}>
        <p>본 프로그램을 개발한</p>
        <p>민사고 및 서울대 수석 김태훈 저자의 신간</p>
        <p className={classes.bold}>[서울대 수석은 이렇게 공부합니다.]</p>
        <p className={classes.bold}>
          출간 기념 1회 무료 분석 이벤트를 진행합니다.
        </p>
        <br />
        <Typography className={classes.lineThrough} variant="h6">
          {`  ₩ 50,000  `}
        </Typography>
        <p style={{ fontSize: 20, marginTop: 12 }}>프로모션가</p>
        <Typography className={classes.lineThrough} variant="h5">
          {`  ₩ 39,000  `}
        </Typography>
        <Typography
          className={classes.bold}
          variant="h5"
          style={{ marginTop: 16 }}
        >
          출간기념 이벤트 기간한정
        </Typography>
        <Typography
          className={classes.bold}
          style={{ marginBottom: 36 }}
          variant="h4"
        >
          ₩ 0
        </Typography>
      </div>
      <div className={classes.buyBookWrapper}>
        <div className={classes.flexCenter}>
          <Button
            component={Link}
            to="/take-test"
            className={classes.takeTestBtn}
            variant="contained"
            size="large"
            color="primary"
            style={{ fontWeight: "bold", color: "#fff" }}
          >
            무료 검사 시작하기
          </Button>
        </div>
        <div className={classes.flexCenter}>
          <Button
            className={classes.buyBook}
            variant="contained"
            size="large"
            color="inherit"
            style={{ fontWeight: "bold", color: "#fff" }}
          >
            <a
              href="https://bit.ly/book_new_event"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.linkToBuyBook}
            >
              책 구경하러 가기
            </a>
          </Button>
        </div>
      </div>
      <div className={classes.instructionWrapper}>
        <div className={classes.hrLine}></div>
        <div className={classes.imgTitleWrapper}>
          <img src={imgHowTo} alt="how-to" className={classes.imgTitle} />
        </div>
        <div className={classes.instructionImgWrapper}>
          <img src={imgFirst} alt="how-to" className={classes.imgFirst} />
        </div>
        <div className={classes.titleWrapper}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            1. 로그인 후 설문을 해주세요.
          </Typography>
        </div>
        <div className={classes.infoWrapper}>
          <div className={classes.info}>
            ※ 현재 카카오톡 간편로그인만 가능합니다.
          </div>
          <div className={classes.info}>
            ※ 설문은 약 100문항으로 7~10분 정도 소요되니 시간 여유를 가지고
            시작해주세요.
          </div>
        </div>
        <div className={classes.instructionImgWrapper}>
          <img src={imgShare} alt="how-to" className={classes.imgFirst} />
        </div>
        <div className={classes.titleWrapper}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            2. 설문을 마치신 후,
          </Typography>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            아이에게 카톡으로 공유해주세요.
          </Typography>
        </div>
        <div className={classes.infoWrapper}>
          <div className={classes.info}>
            ※ 아이가 카카오톡이 없는 경우 부모님의 계정으로 다시 보내고 부모님
            폰으로 설문을 진행해주세요.
          </div>
          <div className={classes.info}>
            ※ 아이가 먼저하고 부모님께 공유를 해도 됩니다.
          </div>
        </div>
        <div className={classes.instructionImgWrapper}>
          <img src={imgKid} alt="how-to" className={classes.imgFirst} />
        </div>
        <div className={classes.titleWrapper}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            3. 아이가 공유된 링크로
          </Typography>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            설문을 합니다.
          </Typography>
        </div>
        <div className={classes.instructionImgWrapper}>
          <img src={imgMail} alt="how-to" className={classes.imgFirst} />
        </div>
        <div className={classes.titleWrapper}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            4. 설문을 마치면 2일 이내에
          </Typography>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            메일로 분석 결과가 발송됩니다.
          </Typography>
        </div>
        <div className={classes.infoWrapper}>
          <div className={classes.info}>
            ※ 메일이 오지 않으면 아래 링크를 눌러 알려주세요.
          </div>
          <div>
            <a
              className={classes.report}
              href={reportLink}
              target="_blank"
              rel="noreferrer"
            >
              {`문제 신고하기 >`}
            </a>
            <div className={classes.clear}></div>
          </div>
        </div>
        <div className={classes.nextBtnWrapper}>
          <Button
            component={Link}
            to="/take-test"
            className={classes.takeTestBtn}
            variant="contained"
            size="large"
            color="primary"
            style={{ fontWeight: "bold", color: "#fff" }}
          >
            무료 검사 시작하기
          </Button>
        </div>
        <div className={classes.sampleBtnWrapper}>
          <Button
            className={classes.sampleBtn}
            variant="contained"
            size="large"
            color="inherit"
            style={{ fontWeight: "bold", color: "#fff" }}
          >
            <a href={SamplePdf} target="_blank" rel="noopener noreferrer">
              샘플 레포트 보기
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
