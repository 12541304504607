import React from 'react';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import PageView from './components/PageView'
import BottomNavBar from './components/BottomNavBar';

const useStyles = makeStyles({
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  dimmingCover: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#000',
    opacity: 0.7
  }
})

function App() {
  const classes = useStyles()
  
  return (
    <>
      <Container maxWidth="xs" className={classes.container}>
        <PageView />
        <BottomNavBar />
      </Container>
    </>
  );
}

export default App;
