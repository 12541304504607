import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import referrerReducer from './store/reducers/referrerReducer'
import userReducer from './store/reducers/userReducer'
import answersReducer from './store/reducers/answersReducer';
import App from './App';
import './stylesheets/index.scss'

import Loading from './components/Loading';

const rootReducer = combineReducers({
  referrer: referrerReducer,
  user: userReducer,
  answers: answersReducer
})

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['user']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const initialState = {}
const store = createStore(persistedReducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
const persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} >
      <PersistGate loading={<Loading />} persistor={persistor}>
        <BrowserRouter>
          <Switch>
            <App />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
