import React from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import Home from '../pages/Home';
import TakeTest from '../pages/TakeTest';
import MyPage from '../pages/MyPage';
import Login from '../pages/Login';
import ShareTest from '../pages/ShareTest';
import SubmitAnswer from '../pages/SubmitAnswer';

const useStyles = makeStyles({
  pageView: {
    height: 'calc(100% - 56px)',
    overflow: 'auto',
    position: 'relative'
  }
})
function PrivateRoute({ children, ...rest }) {
  const user = useSelector(state => state.user.data)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user !== null ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

function OnlyPublicRoute({ children, ...rest }) {
  const user = useSelector(state => state.user.data)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user !== null ? (
          <Redirect
            to={{
              pathname: "/mypage",
              state: { from: location }
            }}
          />
        ) : (
            children
          )
      }
    />
  );
}

const PageView = () => {
  const classes = useStyles()
  return (
    <div className={classes.pageView + " pageview-wrapper"} >
      <Route exact path="/">
        <Home />
      </Route>
      <PrivateRoute exact path="/take-test">
        <TakeTest />
      </PrivateRoute>
      <PrivateRoute exact path="/submit-answer">
        <SubmitAnswer />
      </PrivateRoute>
      <PrivateRoute path="/share-test">
        <ShareTest />
      </PrivateRoute>
      <PrivateRoute exact path="/mypage">
        <MyPage />
      </PrivateRoute>
      <OnlyPublicRoute exact path="/login">
        <Login />
      </OnlyPublicRoute>
    </div>
  )
}

export default PageView;